$light-color: #d8dbe0;
$dark-color: #28292c;

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: "open sans", helvetica, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background-color: #cad6dc; //#effaf6;
  overflow: scroll;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}

select {
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: 0.8rem;
  cursor: inherit;
  line-height: 1.1;

  // Stack above custom arrow
  z-index: 1;

  // Remove dropdown arrow in IE10 & IE11
  // @link https://www.filamentgroup.com/lab/select-css.html
  &::-ms-expand {
    display: none;
  }

  // Remove focus outline, will add on alternate element
  outline: none;
}

.select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;

  select,
  &::after {
    grid-area: select;
  }

  min-width: 15ch;
  max-width: 60ch;

  border: 1px solid var(--select-border);
  border-radius: 0.25em;
  padding: 0.25em 0.5em;

  font-size: 0.8rem;
  cursor: pointer;
  line-height: 1.1;

  // Optional styles
  // remove for transparency
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);

  // Custom arrow
  &:not(.select--multiple)::after {
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }
}

// Interim solution until :focus-within has better support
select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--select-focus);
  border-radius: inherit;
}

select[multiple] {
  padding-right: 0;

  /*
   * Safari will not reveal an option
   * unless the select height has room to 
   * show all of it
   * Firefox and Chrome allow showing 
   * a partial option
   */
  height: 4rem;

  option {
    white-space: normal;

    // Only affects Chrome
    outline-color: var(--select-focus);
  }
}

.select--disabled {
  cursor: not-allowed;
  background-color: #eee;
  background-image: linear-gradient(to top, #ddd, #eee 33%);
}

label {
  font-size: 1rem;
  font-weight: 500;
}

.select + label {
  margin-top: 2rem;
}

.background-toggle-switch {
    cursor: pointer;
    position: relative;
    width: 72px;
    height: 24px;
    margin-left: 20px;
    border-radius: 12px;
    background-color: $light-color;
    border: 1px solid black;

    &.dark {
        background-color: $dark-color;
    }

    .bts-text {
        cursor: pointer;
        text-align: center;
        font-size: 10pt;
        line-height:1.5rem;
        color: $light-color;
        text-transform: capitalize;
        user-select: none;

        &.light {
            color: $dark-color;
        }
    }

    .slider-left.dark {
        position: absolute;
        top: 2px;
        left: 3px;
        border-radius: 9px;
        width: 18px;
        height: 18px;
        background-color: $dark-color;
        box-shadow: inset 7px 0px 0px 0px white;
    }

    .slider-right.light {
        position: absolute;
        top: 4px;
        right: 3px;
        border-radius: 9px;
        width: 14px;
        height: 14px;
        background-color: rgb(253, 253, 59);
    }
}